import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "cover",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "hidden",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  originalCard: {},
 
}));

export default function ReviewItemBlank(props) {
  
  const classes = useStyles();
  return (
   
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}       
      >
        <Card
          className={`${classes.card} ${classes.originalCard}`}
        >
          <CardHeader
            title={
              <CircularProgress size={16} />
            }
          />
          <CardMedia
            className={classes.media}
            component="img"
            image={"/resources/images/no-picture-available.png"}
            title={'productMatch.name'}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/resources/images/no-picture-available.png";
            }}
          />
          <CardContent className={classes.cardContent}>
            
          </CardContent>
          <CardActions>
            
          </CardActions>
        </Card>
      </Grid>  
  );
}
