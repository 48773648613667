import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";


import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";

import Divider from "@material-ui/core/Divider";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import {  useSelector } from "react-redux";

import ReviewPanel from "./RapidMatchingTool/ReviewPanel";
import LeaderBoard from "./RapidMatchingTool/LeaderBoard";
import LastActions from "./RapidMatchingTool/LastActions";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
  toolbarRight: {
    minHeight: "64px",
  },
  toolbarTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width:"100%"
  },
  
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerFooter: {
    borderTop: "2px solid green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "18px",
    //paddingBottom: "px",
  },
  drawerFooterContainer: {
    height: "300px",
    width: drawerWidth,
    overflow: "auto",
  },
  drawerFooterImage: {
    width: 200,
    height: 200,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },
  productImage: {
    objectFit: "contain",
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sliderContainer: {
    width: 300,
    paddingRight: 20,
  },
  totalBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    minHeight: "64px",
    backgroundColor: theme.palette.primary.main,
    // fontSize:"36px",
    color: "#FFF",
  },
  selectListGroup:{
    color: theme.palette.secondary.main,
    // fontSize:"1.2rem",
    backgroundColor:"#fff",
    marginRight:"8px",
    '& div':{
      paddingLeft:"8px"
    }
  },
  coopListLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  totalLeftBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap:"nowrap",
    width: "100%",
    minHeight: "30px",
    backgroundColor: "#10398a",
    fontSize:"1rem",
    color: "#FFF",
  },
}));

export default function RapidMatchingTool(props) {  
  const classes = useStyles();
  const token = useSelector(state => state.session.token);
  // const alertPopup = React.createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [listProjects, setListProjects] = useState([]);
  const [listSimilarTypes, setListSimilarTypes] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(128); 
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id")
  const [filter, setFilter]=useState({q:''})
  
  useEffect(() => {
    const fetchDataListProjects= async () => {
      setIsLoading(true);      
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/projects?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(url,filter, {
        headers: {
          Authorization: token,
        },
      });

      setListProjects(result.data.data);
      setIsLoading(false);
    };
    fetchDataListProjects();
  }, []);
  useEffect(() => {
    const fetchDataSimilarTypes= async () => {
      setIsLoading(true);      
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/product-similar-types`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });

      setListSimilarTypes(result.data.data);
      setIsLoading(false);
    };
    fetchDataSimilarTypes();
  }, []);
  const handleProjectIdChange = (event) => {
    setSelectedProjectId(event.target.value);
  };
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Rapid Matching Tool
          </Typography>          
          
        </Toolbar>
      </AppBar>    
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.totalBlock}>
          <Select className={classes.selectListGroup}  value={selectedProjectId} onChange={handleProjectIdChange} > 
              <MenuItem key={0}  value={0}>-- All Products --</MenuItem>
              {listProjects.length > 0 &&
                listProjects.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
          </Select>
           {/* <TotalBlock ref={totalBlock} /> */}
           {/* <Typography  variant="h6" noWrap>
            Leader board
          </Typography> */}
        </div> 
        <div className={classes.totalLeftBlock}>
          Leader Board
        </div> 
        <div className={classes.drawerContainer}>
            <LeaderBoard selectedProjectId={selectedProjectId}/>
        </div>
        <Divider />
        <div className={classes.totalLeftBlock}>
          Last Actions
        </div>
        <div className={classes.drawerContainer}>
          <LastActions selectedProjectId={selectedProjectId}/>
        </div>      
      </Drawer>  
      <main className={classes.content}>
        <div className={classes.toolbarRight} />
        <div className={classes.reviewPanel}>
            <ReviewPanel
            selectedProjectId={selectedProjectId}  
            similarTypes={listSimilarTypes}
            />
          
        </div>
        
      </main>
      {/* <Alert ref={alertPopup} /> */}
    </div>
  );
}
